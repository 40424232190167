var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'control',
    _vm.sizeClass,
    {
      'has-icons-left': _vm.hasLeftIcon,
      'has-icons-right': _vm.hasRightIcon,
    },
  ]},[_c('label',{class:[_vm.sizeClass]},[(_vm.label)?_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.label)+" "),(_vm.hasAfterLabel)?_vm._t("after-label"):_vm._e(),(_vm.description)?_c('span',{staticClass:"description"},[_vm._v(_vm._s(_vm.description))]):_vm._e()],2):_vm._e(),_c('span',{class:[
        'control-inner',
        { disabled: _vm.isDisabled, readonly: _vm.isReadonly },
      ]},[(_vm.hasLeftIcon)?_c('span',{staticClass:"icon left-icon"},[_vm._t("left-icon")],2):_vm._e(),(!_vm.isTextArea)?_c('input',{staticClass:"input",class:[_vm.sizeClass],attrs:{"placeholder":_vm.placeholder,"disabled":_vm.isDisabled,"type":_vm.type},domProps:{"value":_vm.localValue},on:{"input":_vm.onInput}}):_vm._e(),(_vm.isTextArea)?_c('textarea',{staticClass:"textarea",attrs:{"placeholder":_vm.placeholder,"disabled":_vm.isDisabled,"readonly":_vm.isReadonly},domProps:{"value":_vm.localValue},on:{"input":_vm.onInput}}):_vm._e(),_vm._v(" "),(_vm.hasRightIcon)?_c('span',{staticClass:"icon right-icon"},[_vm._t("right-icon")],2):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }