var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stepper__container"},_vm._l((_vm.visibleSteps()),function(step,idx){return _c('div',{key:idx,ref:`step-${idx}`,refInFor:true,class:[
      'step-container',
      `step-${step.id}`,
      step.name,
      step.status,
      { disabled: !step.enabled },
    ]},[_c('step-header',{attrs:{"step":step},on:{"activate":function($event){return _vm.setActiveStep(step.id)}}}),(step.status === 'active')?_c('div',{staticClass:"step-content"},[_c(_vm.stepActionComponent(step),_vm._b({tag:"component",on:{"change":_vm.changeStepSelected}},'component',_vm.stepActionProps(step),false)),_c('StepNavigation',{attrs:{"step-name":step.name,"is-next-enabled":_vm.isNextEnabled(step.id)},on:{"navigate":_vm.navigate,"restart":_vm.restart,"done":_vm.done}})],1):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }